import { useDisclosure, useToast } from '@cardboard-ui/react';
import { t } from '@lingui/macro';
import Uppy, { UppyFile } from '@uppy/core';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { uppyFactory } from './uppy';
import { uploadErrorSentry } from 'utils/upload/utils';

export enum UploaderContexts {
  PRIVATE_VAULT = 'private-vault',
  SPACE_FOLDER = 'space-folder',
}

interface CardboardUppyFile extends UppyFile {
  uploadToken?: string;
}

type OnUploadFileFunc = (token: string[]) => Promise<{ nodeId: string }>;

type UploaderContextProps = {
  isOpen: boolean;
  isExpanded: boolean;
  onClose: () => void;
  onExpand: () => void;
  onCollapse: () => void;
  onUploadFiles: (args: {
    contextType: UploaderContexts;
    contextId: string;
    contextName: string;
    contextUrl: string;
    files: File[];
    onUpload: OnUploadFileFunc;
  }) => void;
  clearConfirmed: (contextId?: string) => void;
  allFiles: UppyFile[];
  uppy: Uppy;
};

export const UploaderContext = createContext<UploaderContextProps>({
  uppy: new Uppy(),
  isOpen: false,
  isExpanded: false,
  onClose: () => {
    throw new Error('onClose not implemented');
  },
  onExpand: () => {
    throw new Error('onExpand not implemented');
  },
  onCollapse: () => {
    throw new Error('onCollapse not implemented');
  },
  onUploadFiles: () => {
    throw new Error('onUploadFiles not implemented');
  },
  clearConfirmed: () => {
    throw new Error('clearConfirmed not implemented');
  },
  allFiles: [],
});

type UploaderProviderProps = {
  children: React.ReactNode;
};

export const UploaderProvider: React.FC<UploaderProviderProps> = ({
  children,
}) => {
  const toast = useToast();
  const [uppy] = useState(() => uppyFactory({ uploadContext: 'FILES' }));
  const [uploadCallbacks, setUploadCallbacks] = useState<
    Record<string, OnUploadFileFunc>
  >({});

  const upload = useCallback(
    (
      file: File,
      contextType: UploaderContexts,
      contextId: string,
      contextName: string,
      contextUrl: string,
    ) => {
      return new Promise<string>((resolve, reject) => {
        try {
          const fileId = uppy.addFile({
            source: 'file input',
            name: file.name,
            type: file.type,
            data: file,
            meta: {
              relativePath: `allow_duplicates/${Date.now()}`,
              contextType,
              contextId,
              contextName,
              contextUrl,
            },
          });

          resolve(fileId);
        } catch (error) {
          if (
            error instanceof Error &&
            error.message.includes('This file exceeds maximum allowed size')
          ) {
            toast({
              title: t`File is too large. Please limit uploads to 250 gigabytes`,
              status: 'error',
            });
          } else {
            toast({
              title: t`Something went wrong uploading a file`,
              status: 'error',
            });
          }
          reject(error);
        }
      });
    },
    [uppy, toast],
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isExpanded,
    onOpen: onExpand,
    onClose: onCollapse,
  } = useDisclosure({
    defaultIsOpen: true,
  });

  const onCloseUploads = useCallback(() => {
    const confirmedFiles = uppy.getFiles().filter((file) => !!file.meta.nodeId);

    const hasOnGoingUploads = uppy.getFiles().length > confirmedFiles.length;

    if (hasOnGoingUploads) {
      const confirmation = window.confirm(
        t`Are you sure you want to close? This will cancel all ongoing uploads.`,
      );

      if (confirmation) {
        uppy.cancelAll({ reason: 'user' });

        onClose();
      }

      return;
    }

    onClose();
  }, [uppy, onClose]);

  const onUploadFiles: UploaderContextProps['onUploadFiles'] = useCallback(
    ({ files, contextType, contextId, contextName, contextUrl, onUpload }) => {
      onOpen();
      onExpand();

      for (const file of files) {
        upload(file, contextType, contextId, contextName, contextUrl).then(
          (fileId) => {
            setUploadCallbacks((prev) => ({
              ...prev,
              [fileId]: onUpload,
            }));
          },
        );
      }
    },
    [setUploadCallbacks],
  );

  const handleUploadComplete = useCallback(
    (file: UppyFile | undefined) => {
      if (!file) return;

      const uploadToken = (file as CardboardUppyFile).uploadToken!;
      const onUpload = uploadCallbacks[file.id];

      if (!onUpload) {
        throw new Error('No upload callback found for file');
      }

      onUpload([uploadToken])
        .then(({ nodeId }) => {
          uppy.setFileMeta(file.id, {
            nodeId,
          });

          uppy.emit('upload-confirmed', file.id);
        })
        .catch((error) => {
          uppy.emit('upload-error', file, error);
        });
    },
    [uppy, uploadCallbacks],
  );

  const handleFileRemoved = useCallback(() => {
    const files = uppy.getFiles();

    if (files.length === 0) {
      onClose();
    }
  }, [uppy, onClose]);

  const clearConfirmed = useCallback(
    (contextId?: string) => {
      if (contextId) {
        for (const file of uppy.getFiles()) {
          if (file.meta.contextId === contextId && !!file.meta.nodeId) {
            uppy.removeFile(file.id);
          }
        }

        return;
      }

      uppy.getFiles().forEach((file) => {
        uppy.removeFile(file.id);
      });
    },
    [uppy],
  );

  const handleUploadError = useCallback(
    (file: UppyFile | undefined, error: Error) => {
      if (!file) return;

      uppy.setFileMeta(file!.id, {
        nodeId: null,
        error: error,
      });

      uploadErrorSentry(error);
    },
    [uppy],
  );

  useEffect(() => {
    uppy.on('file-removed', handleFileRemoved);
    uppy.on('upload-success', handleUploadComplete);
    uppy.on('upload-error', handleUploadError);

    return () => {
      uppy.off('file-removed', handleFileRemoved);
      uppy.off('upload-success', handleUploadComplete);
      uppy.off('upload-error', handleUploadError);
    };
  }, [uppy, handleUploadComplete]);

  return (
    <UploaderContext.Provider
      value={{
        uppy,
        isOpen,
        isExpanded,
        allFiles: uppy.getFiles(),
        onExpand,
        onCollapse,
        onUploadFiles,
        onClose: onCloseUploads,
        clearConfirmed,
      }}
    >
      {children}
    </UploaderContext.Provider>
  );
};

export const useUploader = () => {
  return useContext(UploaderContext);
};
