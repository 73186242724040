import {
  AccordionButton,
  AccordionIcon,
  Flex,
  Heading,
  HeadingProps,
  HStack,
  Spacer,
} from '@cardboard-ui/react';
import * as React from 'react';

interface Props {
  title: string | React.ReactNode;
  action?: React.ReactNode;
  headingProps?: HeadingProps;
  onClick?: () => void;
}

export const SectionHeader = (props: Props) => {
  const { title, action, headingProps } = props;
  const hProps = headingProps || {
    as: 'h2',
    fontSize: 'lg',
    noOfLines: 2,
  };
  return (
    <Flex align="center" justify="space-between" py="4" px={{ base: 4, md: 6 }}>
      {typeof title === 'string' ? (
        <Heading {...hProps}>{title}</Heading>
      ) : (
        title
      )}

      {action}
    </Flex>
  );
};

export const CollapsableSectionHeader = (props: Props) => {
  const { title, action, headingProps, onClick } = props;
  const hProps = headingProps || { as: 'h2', fontSize: 'lg' };

  return (
    <AccordionButton py="4" px={{ base: 4, md: 6 }} onClick={onClick}>
      <HStack flex={1}>
        {typeof title === 'string' ? (
          <Heading {...hProps}>{title}</Heading>
        ) : (
          title
        )}
        <Spacer />
        {action}
        <AccordionIcon />
      </HStack>
    </AccordionButton>
  );
};
