import { useLocalStorage } from 'hooks/useBrowserStorage';
import React, { PropsWithChildren, useContext } from 'react';
import { FolderViewMode, FolderViewModeContext } from './FolderViewModeContext';
import { Layout, useActiveLayout } from 'utils/LayoutProvider';

const FOLDER_VIEW_MODE_STATE_STORAGE_KEY = 'folder-view-mode';

export const FolderViewModeProvider: React.FC<PropsWithChildren> = (props) => {
  const [viewMode, setViewMode] = useLocalStorage<FolderViewMode>(
    FOLDER_VIEW_MODE_STATE_STORAGE_KEY,
    'table',
  );
  const activeLayout = useActiveLayout();
  const isMobile = activeLayout !== Layout.DESKTOP;
  const folderViewMode = isMobile && viewMode === 'table' ? 'list' : viewMode;

  return (
    <FolderViewModeContext.Provider
      value={{
        viewMode: folderViewMode,
        changeViewMode: setViewMode,
      }}
      {...props}
    />
  );
};

export const useFolderViewMode = () => useContext(FolderViewModeContext);
